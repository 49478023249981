<template>
    <el-card>
        <div class="g-flex"  slot="header">
            <span>管理员列表</span>
            <div class="g-flex-justify-end g-flex-1">
                <el-button size="mini" type="primary" @click="setForm()">新增管理员</el-button>
            </div>
        </div>
        <el-form :inline="true" size="mini" :model="query">
            <el-form-item>
                <el-select slot="label" v-model="query.search_key" placeholder="查询">
                    <el-option label="用户名" value="user_name"></el-option>
                </el-select>
                <el-row>
                    <el-col :span="18">
                        <el-input v-model="query.search_val" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
                    </el-col>
                    <el-col :span="5" :offset="1">
                        <el-button type="primary" @click="getList()">查询</el-button>
                    </el-col>
                </el-row>
            </el-form-item>
        </el-form>
        <el-table stripe size="mini" v-loading="table.loading" :data="table.rows" border>
            <el-table-column prop="id" label="id" width="80" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="user_name" label="用户名" width="100" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="name" label="姓名" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="role_name" label="所属角色" :show-overflow-tooltip="true">
                 <template slot-scope="scope">
                  <span>{{ scope.row.role_id | filtersRoleName(roleList) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" width="140" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <span>{{formatDate(scope.row.create_time, 'yyyy-dd-MM hh:mm')}}</span>
                </template>
            </el-table-column>
            <el-table-column label="后台登录时间" width="140" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <span>{{scope.row.login_time ? formatDate(scope.row.login_time, 'yyyy-dd-MM hh:mm') : '未登录'}}</span>
                </template>
            </el-table-column>
            <el-table-column label="状态" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <span v-if="scope.row.status==0" class="g-red">禁用</span>
                  <span v-else-if="scope.row.status==1" class="g-green">正常</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="150">
                <template slot-scope="scope">
                    <el-button size="mini" icon="el-icon-edit" type="primary" @click="setForm(scope.row)">编辑</el-button>
                    <el-button size="mini" type="danger" @click="del(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination :page-sizes="[15, 30, 60, 100]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange" small hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
        <!--新增和编辑-->
        <el-dialog :title="formData.id?'编辑':'新增'" :close-on-click-modal="false" :visible.sync="form">
            <el-form :model="formData" label-width="100px">
                <el-form-item v-if="formData.id" label="id">{{formData.id}}</el-form-item>
                <el-form-item label="用户名">
                    <el-input v-model="formData.user_name" autocomplete="off" :disabled="formData.id ? true : false"></el-input>
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model="formData.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item  label="所属角色">
                    <el-select v-model="formData.role_id" placeholder="请选择">
                        <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id"> </el-option> 
                    </el-select>
                </el-form-item>
                <el-form-item label="密码">
                    <el-input type="password" v-model="formData.password" :placeholder="formData.id?'为空不修改':'请输入密码'" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="formData.status">
                        <el-option label="禁用" :value="0"></el-option>
                        <el-option label="正常" :value="1"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="form = false">取 消</el-button>
                <el-button type="primary" @click="save()">确 定</el-button>
            </div>
        </el-dialog>
    </el-card>
</template>

<script>
  import { apiGetManageList, apiSaveManage, apiGetRole } from '@/utils/api.js'
  import { formatDate } from '@/utils/index.js'

    export default {
        name: "ManageList",
        async created() {
          await this.getRoleListHandel()
          await this.getList()
        },
        data(){
            return {
                auth:{
                    save: this.$global.ApiList.includes(58)
                },

                query: {search_key: 'user_name', search_val: '', page: 1, limit: 15},
                table: {loading: false, total: 0, rows: []},
                form: false,
                formData: {},
                roleList: []
            }
        },
        filters: {
            filtersRoleName(id, list) {
                let object = list.find(item => item.id == id)
                if(!object) return ''
                return object.name
            }
        },
        methods: {
          formatDate,
            //获取角色列表
            async getRoleListHandel(pageInit = true) {
                if (pageInit) this.query.page = 1
                const { success, data } = await apiGetRole(this.query)
                if(!success) return
                this.roleList = data.list
                console.log(data.list)
            },
            //获取列表
            async getList(pageInit = true) {
                if (pageInit) this.query.page = 1
                this.table.loading = true
                const { success, data } = await apiGetManageList(this.query).catch(() => { this.table.loading = false })
                this.table.loading = false
                if(!success) return
                this.table.rows = data.rows
                this.table.total = data.total
            },
            //每页数量改变
            sizeChange(limit) {
                this.query.limit = limit
                this.getList()
            },
            //设置form
            setForm(formData = { status:1 }) {
                this.formData = JSON.parse(JSON.stringify(formData))
                this.form = true
            },
        
           async save() {
                const loading = this.$loading()
                const { success, data } = await apiSaveManage(this.formData).catch(() => {loading.close()})
                loading.close()
                if(!success) return
                this.form = false
                this.$message.success(data.msg)
                loading.close()
                this.getList()
            },

            //删除管理员
            del(row) {
                this.$confirm('将永久删除'+row.name+'?', '删除提醒').then(async () => {
                    const loading = this.$loading()
                    const { success, data } = await apiSaveManage({id:row.id, status: -1 })
                    if(!success) return loading.close()
                    loading.close()
                    this.$message.success(data.msg)
                    this.getList()
                }).catch(() => {})
            },
        }
    }
</script>